@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100..900&display=swap');

*{
  padding: 0;
  margin: 0;
}

.body{
  background-color: #ccecff;
  min-height: 100dvh;
  flex: 1;
  
}

.cabecario{
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #185fbd;
  height: 100px;
  max-width: 100vw;
}

.nav{
  display: flex;
  gap: 7px;
}

.nav button{
  border: none;
  padding: 10px;
  font-family: "League Spartan", sans-serif;
  font-weight: 600;
  border-radius: 30px;
  font-size: 18px;
  color : #003a89;
  background-color:white;
  min-width: 100px;
}

.nav button:hover{
  cursor: pointer;
}

.ativado{
  background-color: #8ba8ce5e !important;
  color: white !important;
}

.actions{
  display: flex;
  gap: 7px;
}

.actions button{
  border: none;
  padding: 5px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 1s;
}

.check{
background-color: rgba(11, 185, 34, 0.699);
}

.edit{
  background-color: rgba(23, 167, 224, 0.767);
}

.delete{
  background-color: rgba(207, 62, 99, 0.815);
}

.cancel{
  background-color: rgba(207, 62, 99, 0.815);
}

.clip{
  border: none;
  padding: 6px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 1s;
  background-color: #003a89;
}

.Xbutton:hover{
  cursor: pointer;
}

.tab{
  background-color:transparent;
  height: 40px;
  width: 100%;
}

.container{
  display: flex;
  width: 700px !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 50px;
  font-family: "League Spartan", sans-serif;
  font-weight: 600;
  border-radius: 30px;

}
.container h4{
  color: #003a89;
  font-weight: 600;
}

.form{
  display: column;
  flex-direction: row;
  gap: 10px;
}

.table{
  background-color: white;
  padding: 18px;
  border-radius: 20px;
  font-family: "League Spartan", sans-serif;
  font-weight: 600;

} 

.table h4{
  color: #003a89;
  font-weight: 650;
}

.table th{
  color: #003a89;
}

input{
  height: 40px;
  border: none;
  padding: 8px;
  border-radius: 30px;
  font-family:"League Spartan", sans-serif;
  font-weight: 600;
  width: 150px ;
  font-size: 16px;
  background-color: #3673c5;
}

.btn {
  border-radius: 60px !important;
  background-color: #185fbd !important;
  padding: 0 !important;
  box-shadow: none !important;
}

.btn span{
  display: flex !important;
  height: 40px;
  width: 45px;
  justify-content: center !important;
  align-items: center !important;
  color: white !important;
  font-weight: 600 !important;
  font-family: "League Spartan", sans-serif !important; 
}

.botao{
  margin-left: 300px;
  height: 40px;
  border: none;
  padding: 8px;
  border-radius: 30px;
  font-family:"League Spartan", sans-serif;
  font-weight: 600;
  width: 150px ;
  font-size: 16px;
  color: white;
  background-color: #3673c5;
}

.botao:hover{
  cursor: pointer;
}

.linha{
  display: flex;
  flex-direction: row;
  gap: 10px;
}


.tablinks {
  margin-top: 10px;
  padding: 6px;
  width: 300px;
  cursor: pointer;
  background-color: white;
  color:darkblue;
  border: none;
  outline: none;
  transition: background-color 0.3s;
  border-radius: 30px;
  font-size: 20px;
  font-family: "League Spartan", sans-serif;
  font-weight: 600;
}


button:focus{
  background-color: #185fbd !important;
  color: white !important;
}
button:active{
  background-color: #185fbd !important;
  color: white !important;
}


.input-field input[type=text]:focus + label {
  color: #0d18af !important;
}
.input-field input[type=text]:focus {
  border-bottom: 1px solid #2172dd !important;
  box-shadow: 0 1px 0 0 #0d18af !important;
}
input[type=text]:focus + label {
  color: #0d18af !important;
}
input[type=text]:focus {
  border-bottom: 1px solid #2172dd !important;
  box-shadow: 0 1px 0 0 #0d18af !important;
}
.input-field input[type=number]:focus {
  border-bottom: 1px solid #2172dd !important;
  box-shadow: 0 1px 0 0 #0d18af !important;
}
input[type=number]:focus + label {
  color: #0d18af !important;
}
.tabcontent {
  padding: 20px;
  display: block;
}
.tab-content {
  margin-top: 10px;
}

.dropdown button{
  color: rgb(49, 49, 49);
  border-radius: 30px !important;
  border: none !important;
  width: 200px !important;
  font-size: 20px !important;
  font-family: "League Spartan", sans-serif !important;
  font-weight: 600 !important;
  padding-top: 5px;
  padding-bottom: 5px;
}

.dropdown-menu {
  margin-top: 6px !important;
  font-family: "League Spartan", sans-serif !important;
  -webkit-box-shadow: -23px 28px 49px -39px rgba(48,48,48,0.68);
  -moz-box-shadow: -23px 28px 49px -39px rgba(48,48,48,0.68);
  box-shadow: -23px 28px 49px -39px rgba(48,48,48,0.68);
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px !important;
}

.dropdown-menu li {
  border-bottom: #a1a1a14b 0.5px solid;
  padding-top: 4px;
  padding-left: 6px;
  padding-bottom: 4px;
  transition: 200ms;
}
.dropdown-menu li:hover {
  cursor: pointer;
  color: white;
  background-color: #3673c5;
  border-radius: 5px;
}
.imagemError {
  margin-left: -1dvw;
  width: 30dvw;
  filter: drop-shadow(2px 24px 20px rgb(88, 88, 88));
}
.containerError {
  background-color: rgb(223, 223, 223);
  padding: 0 !important;
  margin: 0 !important;
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
}
.titleError{
  font-size: 33px;
  font-family: "League Spartan", sans-serif !important;
  font-weight: 500 !important;
}
.subTitleError{
  font-family: "League Spartan", sans-serif !important;
  font-weight: 500 !important;
  display: flex;
  align-items: center;
  width: 70dvw;
}
.subTitleErrorText{
  font-size: 24px;
  
}
.ErrorButton{
  padding-left: 5px;
  align-self: center;
  background-color: transparent;
  border: none;
  font-size: 24px;
  color: #2172dd;
  text-decoration: underline ;
}

.ErrorButton:hover{
  cursor: pointer ;
  color: #1c5caf;
}

.imageOverlay{
  translate: -50% -50%;
}

.marcar-recebido{
  background-color: #3b79ca;
  color: rgb(255, 255, 255);
  border-radius: 10px;
  padding: 10px;
  border: none;
  font-family: "League Spartan", sans-serif;
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
}

